import axios from 'axios';
var development = "http://localhost:4000";
var production = "https://app.truewin.club";
const aviatorTesting = "http://localhost:3500";
const aviatorProduction = "https://aviator.truewin.club";

export const url = production;
export const aviator = aviatorProduction;

 const API = axios.create({baseURL: url});

 export const signin = (formData) => API.post('/user/signin/', formData);
 export const signup = (formData) => API.post('/user/signup/', formData);
 export const applyBonus = (formData) => API.post('/applyBonus/', formData);
 export const applyWithdrawal = (formData) => API.post('/applyWithdrawal/', formData);
 export const bidData = (bidData) => API.post('/bidData/', bidData);
 export const bank = (formData) => API.post('/addBank/', formData);
 export const upi = (formData) => API.post('/addUpi/', formData);
 export const reset = (formData) => API.post('/resetPassword/', formData);
 export const accountSecurity = (name) => API.post('/accountSecurity/', name);
 export const address = (formData) => API.post('/addAddress/', formData);
 export const kyc = (formData,config)=> API.post('/submitKyc',formData,config);