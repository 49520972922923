import { makeStyles } from "@material-ui/core/styles";
import Header from "../../../images/hed8.jpg";
import MyTask from "../../../images/mytast.jpg";
import Invite from "../../../images/invite.png";
import QR from "../../../images/qr.png";
import Red from "../../../images/red.jpg";
import GalaxyOn from "../../../images/stars-galaxy.gif";
import GalaxyOff from "../../../images/stars-galaxy.jpg";
import Day from "../../../images/day.png";
import CloudsDay from "../../../images/cloud.gif";
import Cloud from "../../../images/day.png"

const gameStyles = makeStyles((theme) => ({
  container: {
   backgroundColor: '#cfe8fc',
   height: '110px',
   
  },
  header: {

    backgroundImage: `url(${Header})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: "120px",
    paddingLeft: "15px",
    paddingRight: "15px",
  

  },
  headerAviator: {
    

    backgroundImage: `url(${Cloud})`,
    backgroundSize: 'cover',
    backgroundPosition: 'start',
    minHeight: "400px",
   
    alignContent: "center",
  

  },
  headerAviatorOn: {
    

    backgroundImage: `url(${CloudsDay})`,
    backgroundSize: 'cover',
    backgroundPosition: 'start',
    minHeight: "400px",
   
    alignContent: "center",
  

  },
  AviatorDayOn: {
    

    backgroundColor: "#9ADAFF",
    minHeight: "400px",
    
    alignContent: "center",
  

  }, 
  cloud: {
    display: 'block',
    position: 'background',
    animation: `$clouds 3s linear infinite`,
  },
  "@keyframes clouds": {
   
    "100%": {
      transform: "translateY(-300px)"
    },
  },
  headerMines: {
    backgroundColor: '#05c0b8',
    
    minHeight: "80px",
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  plane : {
    
    transform: 'translateX(-50%)',
    zIndex: '2',
  },
  planeImg:{
    animation: `$planeImge 0.5s linear infinite`
    
  },
  "@keyframes planeImge": {
   
    "100%": {
      transform: "translateY(-3px)"
    },
    "50%": {
      transform: "translateY(3px)"
    },
    "0%": {
      transform: "translateY(-3px)"
    }
  },
  planeImgAway:{
    animation: `$planeImgeAway 1.5s linear`
    
  },
  "@keyframes planeImgeAway": {
   
    "100%": {
      transform: "translateY(-300px)"
    },
    // "50%": {
    //   transform: "translateY(10px)"
    // },
    // "0%": {
    //   transform: "translateY(-300px)"
    // }
  },
  // mountains : {
    
  //   backgroundImage: `url(${Mountains})`,
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  //   minHeight: "300px",
   
   
    
  //   //backgroundRepeat: 'repeat-x',
  //   //animation: `$myEffect 60s linear infinite`
  // },
  "@keyframes myEffect": {
   
    "100%": {
      transform: "translateX(-3400px)"
    }
  },
  myTask: {
    margin: 0,
    padding: 0,
    backgroundImage: `url(${MyTask})`,
    backgroundSize: 'cover',
    
    height:'100vh'
    
  

  },
  invite: {
    margin: 0,
    padding: 0,
    backgroundImage: `url(${Invite})`,
    backgroundSize: 'cover',
    
    height:'700px'
    
  

  },
  red: {
    margin: 0,
    padding: 0,
    backgroundImage: `url(${Red})`,
    backgroundSize: 'cover',
    
    height:'500px'
    
  

  },
  qr: {
    margin: 0,
    padding: 0,
    backgroundImage: `url(${QR})`,
    backgroundSize: 'cover',
    height:'180px',
    width:'180px'
    
  

  }
}));
export default gameStyles;