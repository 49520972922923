import React, {useState, useEffect} from "react";
import {Typography, Grid, Container, Chip} from '@material-ui/core/';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from "react-router-dom";
import * as api from "../../api/auth";
import axios from 'axios';
import { useHistory } from "react-router-dom";


const MyPromotion = () => {
    const [isAuth, setAuth] = useState(false);
    const history = useHistory();
    const URL =  api.url;

  useEffect(() => {
    
    const loggedInUser =  localStorage.getItem("user");
    if (loggedInUser) {
       
    const foundUser = JSON.parse(loggedInUser);
    setAuth(foundUser);
    const AuthStr = 'Bearer '.concat(foundUser.token);
    axios.get(`${URL}/getUser/${foundUser.result.id}`, { headers: { Authorization: AuthStr } })
      .then(response => {
          setUser(response.data);
          if(response.data[0].block){
          }
    })
      .catch((error) => {
       
        history.push('/login');
    });
     
    }else{
      history.push('/login');

    }
    
  }, []); 
    const [user, setUser] = useState([{bonus: 0.0}]);

    const [level, setLevel] = React.useState(0);
    const handleLevel = (level) => async (event) =>  {
        setLevel(level);
    }
    return (
        <div style={{backgroundColor: '#f2f2f2', minHeight: '100vh'}}>
            <Grid container direction="row" justify="" alignItems="center" style={{paddingLeft: '20px',paddingTop: '15px',paddingBottom: '15px',paddingRight: '20px', backgroundColor: 'white'}}>
                <Grid item xs={4}>  
                <Link to="../profile">
                <ArrowBackIosIcon style={{fontSize:'20px'}} />
                </Link>
                
                                
                 </Grid>
                 <Grid item xs={4}>                    
                <Typography align="center" st>My Promotion</Typography>
                 </Grid>
            </Grid>
            <Container  style={{ backgroundColor:'orange',height: '100px'}}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item style={{paddingTop: '20px'}}>
                            <Typography style={{ color: "white", fontSize:'28px' }}>₹ {user && user[0].bonus.toFixed(2)}</Typography>
                            <Typography style={{ color: "white", fontSize:'16px' }}>My Bonus</Typography>
                        </Grid>
                       
                        <Grid item style={{paddingTop: '20px'}}>
                            <Link to='/mypromotion/apply' style={{textDecoration: 'none', color: 'black'}}>
                            <Chip  label="Apply to Balance" style={{backgroundColor:'white', color: 'orange'}}></Chip>
                            </Link>
                        </Grid>

                    </Grid>

            </Container>
            <Container style={{backgroundColor:'white'}}>
            <Grid container direction="row" justify="space-evenly" style={{padding: '25px',}}>
                <Grid item>
                    <Typography>Bonus Record</Typography>
                </Grid>
                <Link to='/applyRecord' style={{textDecoration: 'none', color: 'black'}}>
                <Grid item>
                    <Typography>Apply Record</Typography>
                </Grid>
                </Link>
            </Grid>
            </Container>
            <Container  style={{backgroundColor:'white',padding:'15px',marginTop:'10px'}}>
            <Grid container direction="row" justify="space-between"> 
            <Grid item>
                <Typography style={{ color: "Black"}}>Promotion</Typography>
            </Grid>
            <Link to='/promotionRecord' style={{textDecoration: 'none', color: 'black'}}>
            <Grid item>
                <Typography style={{ color: "grey"}}>Promotion Record</Typography>
            </Grid>
            </Link>
            </Grid>
            <Typography style={{ color: "Orange", fontSize:'18px', }}>Active members today: {level === 0 ? user && user[0].activeMembers && user[0].activeMembers[`${new Date().getDate()+'/'+new Date().getMonth()}`] && user[0].activeMembers[`${new Date().getDate()+'/'+new Date().getMonth()}`]['0'] ? user[0].activeMembers[`${new Date().getDate()+'/'+new Date().getMonth()}`]['0'].length :'0' : level === 1 ? user && user[0].activeMembers && user[0].activeMembers[`${new Date().getDate()+'/'+new Date().getMonth()}`] && user[0].activeMembers[`${new Date().getDate()+'/'+new Date().getMonth()}`]['1'] ? user[0].activeMembers[`${new Date().getDate()+'/'+new Date().getMonth()}`]['1'].length :'0' : user && user[0].activeMembers && user[0].activeMembers[`${new Date().getDate()+'/'+new Date().getMonth()}`] && user[0].activeMembers[`${new Date().getDate()+'/'+new Date().getMonth()}`]['2'] ? user[0].activeMembers[`${new Date().getDate()+'/'+new Date().getMonth()}`]['2'].length :'0'}</Typography>

            <Grid container direction="row" style={{padding: '10px'}}>
                <Grid item xs={4} onClick={handleLevel(0)}>
                    <Container style={{width: '100%', height: '30px', border: '1px solid', borderColor: 'Orange',backgroundColor: level === 0 ? "Orange" : 'white' }}>
                        <Typography align="center" style={{ color: level === 0 ? 'white' : "black", paddingTop: "3px"}}>Level 1</Typography>
                    </Container>
                </Grid>
                <Grid item xs={4} onClick={handleLevel(1)}>
                    <Container style={{width: '100%', height: '30px', border: '1px solid', borderColor: 'Orange',backgroundColor: level === 1 ? "Orange" : 'white'}}>
                        <Typography align="center" style={{ color: level === 1 ? 'white' : "black", paddingTop: "3px"}}>Level 2</Typography>
                    </Container>
                </Grid>
                <Grid item xs={4} onClick={handleLevel(2)}>
                    <Container style={{width: '100%', height: '30px', border: '1px solid', borderColor: 'Orange',backgroundColor: level === 2 ? "Orange" : 'white'}}>
                        <Typography align="center" style={{ color: level === 2 ? 'white' : "black", paddingTop: "3px"}}>Level 3</Typography>
                    </Container>
                </Grid>
            </Grid>
            <Grid container direction="row" style={{paddingTop: "20px"}}>
               <Grid item xs={6}>
                   <Typography style={{ color: "grey",fontSize: '14px'}}>Total People</Typography>
                   <Typography style={{ fontSize: "18px" }}>{user[0][`level${level}`] ? user[0][`level${level}`] && user[0][`level${level}`].length : '0'}</Typography>
               </Grid>
               <Grid item xs={6}>
                   <Typography style={{ color: "grey", fontSize: '14px'}}>Contribution(Rupees)</Typography>
                   <Typography style={{ fontSize: "18px" }}>₹{user && user[0][`level${level}contribution`] ? user && user[0][`level${level}contribution`] && user[0][`level${level}contribution`].toFixed(2) : '0'}</Typography>
               </Grid>
            </Grid>
            </Container>
            <Container style={{padding: "15px", marginTop: '10px',backgroundColor: 'white'}}>
            <Grid container direction="row" justify="space-between">
              <Grid item>
                  <Typography style={{ color: "#3C6E71"}}>Invite Friends</Typography>
              </Grid>

              <Grid item>
              <Link to='/invite' style={{textDecoration: 'none', color: 'black'}}>
              <Chip  label="Go Now" style={{backgroundColor:'Orange', color: 'white'}}></Chip>
              </Link>
              </Grid>
            </Grid>
            </Container>
            

            
        </div>
    )
}

export default MyPromotion ; 
