import React from "react";
import ReactDom from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { reducers } from './reducers';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import './index.css';



const store = createStore(reducers, compose(applyMiddleware(thunk)));

ReactDom.render(
  <BrowserRouter>
  <Provider store={store}>
        {/* <h5 style={{padding: '30px'}}>Server is upgrading. Please allow us some time</h5>  */}

  <App />
  </Provider>
   
  </BrowserRouter>,

  document.getElementById("root")
);
