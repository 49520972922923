import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Container,
  FilledInput,
  InputLabel,
  FormControl,
  Button,
  CircularProgress,
  Dialog,
  Paper,
} from "@material-ui/core/";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import * as api from "../../api/auth";
import Photo from "../../images/pan.png";
import axios from "axios";
import { useDispatch } from "react-redux";

const MyKYC = () => {
  const URL = api.url;
  const dispatch = useDispatch();

  const [user, setUser] = useState();
  const history = useHistory();

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      
      const AuthStr = "Bearer ".concat(foundUser.token);
      axios
        .get(`${URL}/getUser/${foundUser.result.id}`, {
          headers: { Authorization: AuthStr },
        })
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          console.log(error);
          history.push("/login");
        });
    } else {
      history.push("/login");
    }
  }, []);


  const [formData, setFormData] = useState({name:''});
  const [isLoading, setLoading] = useState(false);
  const [openDialog, setDialog] = React.useState({ open: false, body: "" });
  const [file,setFile] = React.useState();
  const fileData = new FormData();
  const handleChange = (e) => {
    
    const AuthStr = "Bearer ".concat(user && user[0].token);
    if(e.target.files){
      setFormData({
        ...formData,
        file: e.target.files[0],
        userId: user && user[0].id,
        auth:AuthStr
      });
      
      
    }else{
      setFormData({
        ...formData,
        name: e.target.value,
        userId: user && user[0].id,
        auth:AuthStr
      });
    }
   

    //setUpi({...upi, [e.target.name]: e.target.value});
  };
  const dialogClose = () => {
    setDialog({ ...openDialog, open: false });
  };

  const handleSubmit = async (e) => {
    
    e.preventDefault();
    
    setLoading(() => true);
        if (
      formData.name === "" ||
      !formData.file
    ) {
      setDialog({
        ...openDialog,
        open: true,
        body: "Please Enter all fields correctly",
      });
      setLoading(() => false);

    } else {
      try {
         const imageData = new FormData();
         imageData.append('image',formData.file);
         await axios.put(`${URL}/submitKyc`,imageData,{'Content-Type': 'multipart/form-data'},)
         setLoading(() => false);
      } catch (error) {
        setDialog({
          ...openDialog,
          open: true,
          body: error.response.data.error,
        });
        setLoading(() => false);
      }
    }

    
  };

  return (
    <div style={{ paddingBottom: "50px" }}>
      <Dialog
        open={isLoading}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Container
          align="center"
          style={{
            backgroundColor: "black",
            opacity: "0.6",
            height: "100px",
            paddingTop: "10px",
          }}
        >
          <CircularProgress style={{ color: "white" }} />
          <Typography style={{ paddingTop: "10px", color: "white" }}>
            Please Wait!
          </Typography>
        </Container>
      </Dialog>
      <Dialog
        open={openDialog.open}
        onClose={dialogClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Container
          style={{ backgroundColor: "black", opacity: "0.6", height: "50px" }}
        >
          <Typography style={{ paddingTop: "10px", color: "white" }}>
            {openDialog.body}
          </Typography>
        </Container>
      </Dialog>

      <Grid
        container
        direction="row"
        justify=""
        alignItems="center"
        style={{
          paddingLeft: "20px",
          paddingTop: "15px",
          paddingBottom: "15px",
          paddingRight: "20px",
          backgroundColor: "white",
        }}
      >
        <Grid item xs={4}>
          <ArrowBackIosIcon
            onClick={history.goBack}
            style={{ fontSize: "20px" }}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography align="center" st>
            My KYC
          </Typography>
        </Grid>
      </Grid>

     {
        user && user[0].isVerified ? <div>

        </div> : <div>
        <Typography
        style={{
          paddingLeft: "30px",
          paddingTop: "10px",
          paddingBottom: "15px",
          paddingRight: "20px",
        }}
      >
        Add KYC Details
      </Typography>
      <Grid container direction="row" alignItems="center" style={{paddingRight: '25px', paddingLeft: '25px'}}>
                  <Grid item xs={4}>
                    <Typography style={{fontSize: '13px',fontWeight: 'bold', color: 'black'}}>Your Name: </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth
                      
                      variant="filled"
                     
                    >
                      <InputLabel  >
                      your name as per pan card
                      </InputLabel>
                      <FilledInput
                        fullWidth='true'
                        name="name"
                        id="filled"
                       onChange={handleChange}
                        style={{backgroundColor: 'white', textUnderlineOffset: 'none'}}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Container style={{height: '30px'}}></Container>
        {/* <Typography align="center">
            <Container style={{width: '100px', height: '80px', backgroundColor: '#3C6E71',borderRadius: '10px'}}>
            <Button>
            <img src={Photo} height={20} alt="photo" style={{marginTop: '10px',marginBottom: '10px'}}/> */}
            <Typography align="center" style={{fontSize: '13px',fontWeight: 'bold', color: 'black'}}>Upload PAN Card </Typography>
           <Typography align="center" style={{fontSize: '10px',fontWeight: 'bold', color: 'black',paddingTop: '10px'}}>
           
           <input
              accept="image/*"
              name="file"
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleChange}
              // style={{ display: "none"}}
            />
            </Typography>
            {/* </Button> */}
             
             {/* </Container> */}
             {/* </Typography>   */}
            <Container style={{height: '30px'}}></Container>

            <Typography align="center">
            <Container onClick={handleSubmit} style={{width: '200px', backgroundColor: '#3C6E71',borderRadius: '10px'}}>
              <Typography style={{fontSize: '18px',fontWeight: 'bold', color: 'white',padding: '10px'}}>Submit</Typography>
            </Container>
            </Typography>        
        </div>
     }
     

    </div>
  );
};

export default MyKYC;
