import React, {useState, useEffect} from "react";
import {  Grid, Typography, List, ListItem, Box} from "@material-ui/core";
// import Box from '@mui/material/Box'; 

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from "react-router-dom";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import * as api from "../../../api/auth";


const WithdrawalHistory = () => {
    const history = useHistory();
    const [record, setRecord] = useState();
    const URL =  api.url;



  useEffect(() => {
    
    const loggedInUser =  localStorage.getItem("user");
      if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      const AuthStr = 'Bearer '.concat(foundUser.token); 
      axios.get(`${URL}/getWithdrawal/${foundUser.result.id}`, { headers: { Authorization: AuthStr } })
      .then(response => {
          setRecord(response.data);
    })
      .catch((error) => {
        
        history.push('/login');
    });
      

         
    }else{
      window.location.replace("/login");

    }
    
  }, []); 
   
    
    return (
        <div>
            <Grid container direction="row" justify="" alignItems="center" style={{paddingLeft: '20px',paddingTop: '15px',paddingBottom: '15px',paddingRight: '20px', backgroundColor: 'white'}}>
                <Grid item xs={4}>  
                <Link to="../wallet">
                <ArrowBackIosIcon style={{fontSize:'20px'}} />
                </Link>
                
                                
                 </Grid>
                 <Grid item xs={4}>                    
                <Typography align="center" st>Withdrawal Records</Typography>
                 </Grid>
            </Grid>
            <Grid container direction="row" style={{padding: '10px'}}>
                <Grid item xs={5}>
                    <Typography>Date</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography>Amount</Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography>Status</Typography>
                </Grid>
            </Grid>
            {record && record.map && record.map(record => (
            <List component="nav" aria-label="main mailbox folders"  >
            <ListItem >
                <Grid
                container
                alignItems="center"
                justify="space-between"
                direction="row"
                >
                <Grid item xs={5}>
                    <Typography style={{fontSize:'10px'}}>{new Date(record.date).toLocaleString()}</Typography>
                </Grid>
                <Grid item xs={3}>
                <Typography style={{fontWeight: "bold",}}>₹{(record.amount)}</Typography>
                </Grid>
                <Grid item xs={4}>
                <Box sx={{ borderRadius: '2px', borderColor: 'orange' }}>
                <Typography style={{color: record.status === 'Success' ? 'green': 'black'}}>{record.status}</Typography>
                </Box>
                </Grid>
                </Grid>
                
                </ListItem>
            </List>
            ))}
            
        </div>
    )
}

export default WithdrawalHistory;
