import React, {useState, useEffect} from "react";
import {Typography,Dialog, Grid, Container, Button,FilledInput,InputLabel,FormControl,CircularProgress} from '@material-ui/core/';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as api from "../../../api/auth";
import { useHistory } from "react-router-dom";
import axios from 'axios';





const Withdrawal = () => {
  const [isAuth, setAuth] = useState(false);
  const [user, setUser] = useState();
  const [loader, setLoader] = React.useState(false);
  const [isBlocked, setBlock] = useState({open: false, msg: ''});
  const URL =  api.url;


  useEffect(() => {
    
    const loggedInUser =  localStorage.getItem("user");
    if (loggedInUser) {
       
    const foundUser = JSON.parse(loggedInUser);
    setAuth(foundUser);
    const AuthStr = 'Bearer '.concat(foundUser.token);
    axios.get(`${URL}/getUser/${foundUser.result.id}`, { headers: { Authorization: AuthStr } })
      .then(response => {
          setUser(response.data);
          if(response.data[0].block){
            setBlock({...isBlocked, open: true, msg: response[0].block})
          }
    })
      .catch((error) => {
       
        history.push('/login');
    });
     
    }else{
      history.push('/login');

    }
    
  }, []); 
    const history = useHistory();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({amount: 0});
    const [fee, setFee] = useState(0);
    const [openDialog, setDialog] = React.useState({open: false, body: ''});

    const dialogClose = () => {
      setDialog({ ...openDialog, open: false, body: ''});
    };
    function between(x, min, max) {
      return x >= min && x <= max;
    }
    
    const handleChange = (e) => {
      const AuthStr = 'Bearer '.concat(isAuth.token);   
               
      setFormData({ ...formData, [e.target.name]: e.target.value, userId: user[0].id,auth: AuthStr});
    };
        
    const handleSubmit = async (e) => {
        
        e.preventDefault();
        const date = new Date();
        var hour = date.getHours();
        var day = date.getDay();
        // setDialog({...openDialog, open: true, body: "We are updating our plateform. Functionality like recharge, withdrawal will be temporary paused. You can place bid and earn. We are sorry for any inconvenience caused. ThankYou !"})
       
        if(parseInt(formData.amount) < 230){
          setDialog({...openDialog, open: true, body: 'Minimum ₹230'});
        }else{
          if(parseInt(formData.amount) > user[0].balance){

            setDialog({...openDialog, open: true, body: 'Insufficient Fund'});
  
          }else{
            if(user[0].upi == null || user[0].bank[0] == null){
              setDialog({...openDialog, open: true, body: 'Please Add UPI or a bank account first'});

            }else{
              if( user[0].demo === true){
                setDialog({...openDialog, open: true, body: 'Withdrawal not allowed in your account!'});

              }else{
                // setDialog({...openDialog, open: true, body: 'Please wait'});

                setLoader(true);
                
          try {
              
              const { data } = await api.applyWithdrawal(formData);
              dispatch({ type: "applyWithdrawal", data: data });
              setLoader(false);
              history.push("/wallet");
           
          } catch (error) {
            setLoader(false);
              setDialog({...openDialog, open: true, body: error.response.data.error});
  
          }
          setLoader(false);
              }
              
            }
            
          }
        }
        
        
        
        
      };
    
   
    return (
        <div>
          <Dialog
          open={isBlocked.open}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <Container  align="center" style={{backgroundColor: 'black', opacity: '0.6',height: '80px',paddingTop: "10px"}}>
           <Typography style={{paddingTop: '10px', color: "white" }}>{isBlocked.msg}</Typography>
          </Container>
          
          
        </Dialog>
            <Grid container direction="row" justify="" alignItems="center"style={{paddingLeft: '20px',paddingTop: '15px',paddingBottom: '15px',paddingRight: '20px', backgroundColor: 'white'}}>
                <Grid item xs={4}>  
                <Link to="../wallet">
                <ArrowBackIosIcon style={{fontSize:'20px'}} />
                </Link>
                
                                
                 </Grid>
                 <Grid item xs={4}>                    
                <Typography align="center" st>Withdrawal</Typography>
                 </Grid>
            </Grid>
            <Typography variant="h5" style={{paddingLeft: '35px', paddingTop: '15px'}}>My Balance</Typography>
            <Typography variant="h5" style={{paddingLeft: '35px',fontWeight: 'bold'}}>              ₹ {user && (user[0].balance + (user[0].integral?user[0].integral:0)).toFixed(2)}</Typography>
            <Typography  style={{paddingLeft: '35px', paddingTop: '15px'}}>Amount: ₹0 - ₹1000, fee ₹30</Typography>
            <Typography  style={{paddingLeft: '35px'}}>Amount: ₹1000 and above, fee 3%</Typography>
            <Typography  style={{paddingLeft: '22px', paddingTop: '20px',fontWeight: 'bold'}}>Withdrawal Amount</Typography>
            <Grid container direction="row" justify="flex-start" alignItems="center" style={{paddingLeft: '22px',paddingTop: '20px'}}>
             <Grid item><Typography style={{fontWeight: 'bold'}}>₹</Typography></Grid>
             <Grid item>
             <FormControl fullWidth
                      
                      variant="filled"
                      
                    >
                      <InputLabel   >
                      Enter Amount
                      </InputLabel>
                      <FilledInput
                        type="number"
                        fullWidth='true'
                        name="amount"
                        id="filled"
                        onChange={handleChange}
                        style={{backgroundColor: 'white', textUnderlineOffset: 'none'}}
                      />
                    </FormControl>
             </Grid>
            </Grid>
            <Typography  style={{paddingLeft: '28px',paddingTop: '5px'}}>The balance that can be withdrawn is: ₹{user && user[0].balance.toFixed(2)}</Typography>
            {
              user && user[0].integral ?
                 <div>
                              <Typography  style={{paddingLeft: '22px',paddingTop: '5px',fontWeight: 'bold'}}>Integral: ₹{user && user[0].integral.toFixed(2)}</Typography>
                              <Typography  style={{paddingLeft: '20px'}}>You can use integral amount to place bids, winnings from this funds can be withdrawn</Typography>


                 </div>
                 :
                 <div></div>
            }

            {/* <Typography  style={{paddingLeft: '28px',paddingTop: '5px'}}>withdrawn fee(tax - inclusive) ₹{fee}</Typography> */}
            <Typography  style={{paddingLeft: '22px', paddingTop: '20px',fontWeight: 'bold'}}>Single Withdrawal Limit</Typography>
            <Typography  style={{paddingLeft: '28px',paddingTop: '5px'}}>maximum amount: ₹50000</Typography>
            <Typography  style={{paddingLeft: '28px',paddingTop: '5px'}}>minimum amount: ₹230</Typography>            
            <Typography  style={{paddingLeft: '22px',fontWeight: 'bold',paddingTop: '5px'}}>Service Time</Typography>
            <Typography  style={{paddingLeft: '28px',paddingTop: '5px'}}>Monday 11:30-16:30</Typography>
            <Typography  style={{paddingLeft: '28px'}}>Tuesday 11:30-16:30</Typography>
            <Typography  style={{paddingLeft: '28px'}}>Wednesday 11:30-16:30</Typography>
            <Typography  style={{paddingLeft: '28px'}}>Thursday 11:30-16:30</Typography>
            <Typography  style={{paddingLeft: '28px'}}>Friday 11:30-16:30</Typography>
            {user && user[0].upi && user[0].upi != null? 
             <Typography align="center" style={{fontWeight: 'bold',paddingTop: '15px'}}>UPI Details: {user && user[0].upi}</Typography>
            :null}
            {user && user[0].bank[0] && user[0].bank[0] != null ? 
             <Typography align="center" style={{fontWeight: 'bold',paddingTop: '15px'}}>Bank Details: {user && user[0].bank[0].name+user[0].bank[0].account.substr(-4)}</Typography>
            :null}
            <Container onClick={handleSubmit} style={{backgroundColor: 'orange',marginTop: '15px', height: '50px', width: '100%',borderRadius: '8px'}}>
                <Typography  align="center" ><Button type="submit"  style={{paddingTop: '12px',color: 'white', textTransform: 'none'}}>Withdrawal</Button></Typography>
              </Container>
              <Link to="/withdrawalHistory"  style={{textDecoration: 'none', color: 'black'}}>

              <Container style={{backgroundColor: 'grey',marginTop: '15px',marginBottom: '100px', height: '50px', width: '100%',borderRadius: '8px'}}>
                <Typography align="center" style={{paddingTop: '12px',color: 'white'}}>Historical</Typography>
              </Container>
              </Link>
              <Dialog
                open={loader}
                PaperProps={{
                  style: {
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                  },
                }}
              >
                <Container  align="center" style={{backgroundColor: 'black', opacity: '0.6',height: '100px',paddingTop: "10px"}}>
                <CircularProgress style={{color: 'white',}} />
                <Typography style={{paddingTop: '10px', color: "white" }}>Please Wait!</Typography>
                </Container>
                
                
              </Dialog>
              <Dialog
                    open={openDialog.open}
                    onClose={dialogClose}
                    PaperProps={{
                    style: {
                        // backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                    }}
                
                    
                >
                    <Container style={{
                      // backgroundColor: 'black', opacity: '0.6', height: '50px'
                      }}>
                <Typography style={{padding: '10px', color: "black" }}>{openDialog.body}</Typography>
                </Container>
                
                
              </Dialog>
              
        </div>
    )
}

export default Withdrawal
