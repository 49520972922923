import React, {useState, useEffect}from 'react';
import {Typography, Grid } from '@material-ui/core/';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from "react-router-dom";
import Timer from '../../../images/timer.png'



const RedEnvelope = () => {
    const [isAuth, setAuth] = useState(false);

  useEffect(() => {
    
      const loggedInUser =  localStorage.getItem("user");
    if (loggedInUser) {
      const foundUser = JSON.parse(loggedInUser);
      setAuth(foundUser);
      
     
    }else{
      
      window.location.replace("/login");

    }
    
  }, []); 
    return (
        <div>
            <Grid container direction="row" justify="" alignItems="center" style={{paddingLeft: '20px',paddingTop: '15px',paddingBottom: '15px',paddingRight: '20px', backgroundColor: 'white'}}>
                <Grid item xs={4}>  
                <Link to="../profile">
                <ArrowBackIosIcon style={{fontSize:'20px'}} />
                </Link>
                
                                
                 </Grid>
                 <Grid item xs={4}>                    
                <Typography align="center" variant="h6">Red Envelope</Typography>
                 </Grid>
            </Grid>
            <Grid container alignItems="center" justify="center" style={{paddingTop: '30px'}}>
                <Grid item>
                <img src={Timer} height={100} alt=""></img>
                </Grid>
           
            </Grid>
            <Typography align="center" style={{paddingTop: '30px'}}>Applying...</Typography>
            <Typography  align="center" style={{paddingTop: '20px',color: 'grey'}}>The merchant is processing, please wait patiently</Typography>
            <Grid container direction="row" justify="space-between" style={{padding: '20px'}}>
                <Grid item>
                    <Typography style={{fontSize:'14px'}}>Apply Time</Typography>
                </Grid>
                <Grid item>
                    <Typography style={{fontSize:'14px'}}>23 March 2023, 05:01:00 pm</Typography>
                </Grid>
            </Grid>
            
            

            
        </div>
    )
}

export default RedEnvelope ; 
