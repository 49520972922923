import React, {useState, useEffect} from "react";
import { Container, Grid, Typography, List, ListItem,Dialog,DialogContent} from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from "react-router-dom";
import * as api from "../../api/auth";
import axios from 'axios';
import { useHistory } from "react-router-dom";

const PromotionRecord = () => {
    const [isAuth, setAuth] = useState(false);
    const [memberRecharge, setMemberRecharge] = useState({balance: 0});
    const history = useHistory();
    const URL =  api.url;

  useEffect(() => {
    
    const loggedInUser =  localStorage.getItem("user");
    if (loggedInUser) {
       
    const foundUser = JSON.parse(loggedInUser);
    setAuth(foundUser);
    const AuthStr = 'Bearer '.concat(foundUser.token);
    axios.get(`${URL}/getUser/${foundUser.result.id}`, { headers: { Authorization: AuthStr } })
      .then(response => {
          setUser(response.data);
          if(response.data[0].block){
          }
    })
      .catch((error) => {
       
        history.push('/login');
    });
     
    }else{
      history.push('/login');

    }
    
  }, []); 
  const [ruleDialog, setRule] = React.useState(false);
  const ruleClose = () => {
    setRule(false);
  };
  const openRule = () => {
    setRule(true);
  };

  // const getRecharge = (phoneNumber) => {
    
  //   var memberRequest = new XMLHttpRequest();
  //   memberRequest.onreadystatechange = function () {
  //     if (memberRequest.readyState === 4 && memberRequest.status === 200) {
  //       const response = JSON.parse(memberRequest.response);
  //       setMemberRecharge(response);
        
  //     }
  //   };
  //   memberRequest.open("GET", `https://secured.servider.net/playerJankaari/${phoneNumber}`, true);
  //   memberRequest.send();
  //   setRule(true);
    
  // }
    const [user, setUser] = useState([{bonus: 'Loading'}]);
   
    const [level, setLevel] = useState(0);
    const handleLevel = (level) => async (event) =>  {
        setLevel(level);
    }
    return (
        <div>
            {/* <Dialog
        open={ruleDialog}
        onClose={ruleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
       
        <DialogContent>
          <Typography align="left" style={{ fontSize: '16px', fontWeight: 'bold'}}>Member Details</Typography>
          <Typography align="left" style={{ fontSize: '12px'}}>User Balance: {memberRecharge.balance}</Typography>

        </DialogContent>
        
      </Dialog> */}
            <Grid container direction="row" justify="" alignItems="center" style={{paddingLeft: '20px',paddingTop: '15px',paddingBottom: '15px',paddingRight: '20px', backgroundColor: 'white'}}>
                <Grid item xs={4}>  
                <Link to="../mypromotion">
                <ArrowBackIosIcon style={{fontSize:'20px'}} />
                </Link>
                
                                
                 </Grid>
                 <Grid item xs={4}>                    
                <Typography align="center" st>Promotion Record</Typography>
                 </Grid>
            </Grid>
            <Grid container direction="row" style={{padding: '10px'}}>
                <Grid item xs={4} onClick={handleLevel(0)}>
                    <Container style={{width: '100%', height: '30px', border: '1px solid', borderColor: 'Orange',backgroundColor: level === 0 ? "Orange" : 'white' }}>
                        <Typography align="center" style={{ color: level === 0 ? 'white' : "black", paddingTop: "3px"}}>Level 1</Typography>
                    </Container>
                </Grid>
                <Grid item xs={4} onClick={handleLevel(1)}>
                    <Container style={{width: '100%', height: '30px', border: '1px solid', borderColor: 'Orange',backgroundColor: level === 1 ? "Orange" : 'white'}}>
                        <Typography align="center" style={{ color: level === 1 ? 'white' : "black", paddingTop: "3px"}}>Level 2</Typography>
                    </Container>
                </Grid>
                <Grid item xs={4} onClick={handleLevel(2)}>
                    <Container style={{width: '100%', height: '30px', border: '1px solid', borderColor: 'Orange',backgroundColor: level === 2 ? "Orange" : 'white'}}>
                        <Typography align="center" style={{ color: level === 2 ? 'white' : "black", paddingTop: "3px"}}>Level 3</Typography>
                    </Container>
                </Grid>
            </Grid>
            {user[0][`level${level}`] && user[0][`level${level}`].map(member => (
            <List component="nav" aria-label="main mailbox folders"  >
            <ListItem >
                <Grid
                container
                alignItems="center"
                justify="space-between"
                direction="row"
                >
                <Grid item>
                    <Typography>{member.name}</Typography>
                    {/* <Typography style={{fontSize: 12, color: 'blue'}} onClick={getRecharge(member.phone)}>View Details</Typography> */}
                </Grid>
                <Grid item>
                <Typography>{new Date(member.date).toLocaleString()}</Typography>
                </Grid>
                </Grid>
                
                </ListItem>
            </List>
            ))}
            
        </div>
    )
}

export default PromotionRecord
