import React,{useState, useEffect} from 'react';
import {Typography, Grid, Container, FilledInput, InputLabel,FormControl,Button,CircularProgress,Dialog, Paper} from '@material-ui/core/';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as api from "../../api/auth";
import SmsIcon from '@material-ui/icons/Sms';
import axios from 'axios';


const MyBank = () => {
  const URL =  api.url;
  const [isAuth, setAuth] = useState(false);
  const [user, setUser] = useState();
  const history = useHistory();
  // const SITE_KEY = "6LcM-EMmAAAAABHRB0zrtqW4SkGuWXQiAxSDsbIF";
   
 
   
  // useEffect(() => {
  //   const loadScriptByURL = (id, url, callback) => {
  //     const isScriptExist = document.getElementById(id);
   
  //     if (!isScriptExist) {
  //       var script = document.createElement("script");
  //       script.type = "text/javascript";
  //       script.src = url;
  //       script.id = id;
  //       script.onload = function () {
  //         if (callback) callback();
  //       };
  //       document.body.appendChild(script);
  //     }
   
  //     if (isScriptExist && callback) callback();
  //   }
   
  //   // load the script by passing the URL
  //   loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      
  //   });
  // }, []);

  useEffect(() => {
    
    const loggedInUser =  localStorage.getItem("user");
    if (loggedInUser) {
       
    const foundUser = JSON.parse(loggedInUser);
    setAuth(foundUser);
    const AuthStr = 'Bearer '.concat(foundUser.token);
    axios.get(`${URL}/getUser/${foundUser.result.id}`, { headers: { Authorization: AuthStr } })
      .then(response => {
          setUser(response.data);
          
    })
      .catch((error) => {
        
        history.push('/login');
    });
     
    }else{
      history.push('/login');

    }    
  }, []); 
  const dispatch = useDispatch();
  
  const [counter, setCounter] = useState(90);
  const initialState = {phone: '', name: '', ifsc: '', account: '', confirm: '', state: '', city: '', address: '',email: '', code: ''};
  const [formData, setFormData] = useState(initialState);
  const [upi, setUpi] = useState({upi: ''});
  const [isLoading, setLoading] = useState(false);
  const [openDialog, setDialog] = React.useState({open: false, body: ''});
  const [canRun, setRun] = useState(false);
  const [otpDialog, setOtpDialog] = React.useState({open: false, body: ''});

   
     const otpdialogClose = () => {
       setOtpDialog({ ...otpDialog, open: false, body: ''});
     };
    if(canRun) counter > 0 &&  setTimeout(() => setCounter(counter - 1), 1000) ;


    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value, userId: isAuth.result.id, userPhone: isAuth.result.phone});
      setUpi({...upi, [e.target.name]: e.target.value});
    };
    const dialogClose = () => {
      setDialog({ ...openDialog, open: false});
    };
    const UPIChange = (e) => {
      setUpi({...upi, [e.target.name]: e.target.value, userId: isAuth.result.id, userPhone: isAuth.result.phone})
    }
    const handleUPI = async (e) => {
      e.preventDefault();
      if(!upi.upi.includes('@') || upi.upi.length < 5){
        setDialog({ ...openDialog, open: true, body: 'Invalid UPI'})
      }else{
      setLoading(() => true);
      
      try {
        
        setOtpDialog({otpDialog, open: true});
        // const { data } = await api.upi(upi);
        // dispatch({ type: "upi", data: data });
        // history.push("/profile");
      
      } catch (error) {
      setDialog({ ...openDialog, open: true, body: error.response.data.error})
      }
      setLoading(() => false);
      }


    }
    const submitOTP = async() => {
      setLoading(() => true);
      
      try {
        
        if(level === 0){
          const AuthStr = 'Bearer '.concat(isAuth.token);   
          setUpi({...upi, auth: AuthStr});
          const { data } = await api.upi(upi);
          dispatch({ type: "upi", data: data });
          history.push("/profile");
        }
        else{
          const AuthStr = 'Bearer '.concat(isAuth.token);   
          setFormData({...formData, auth: AuthStr}); 
          const { data } = await api.bank(formData);
          dispatch({ type: "bank", data: data });
          history.push("/profile");

        }
        
      
      } catch (error) {
      setDialog({ ...openDialog, open: true, body: error.response.data.error})
      }
      
      setLoading(() => false);
      
    }
    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(() => true);
      if(formData.name === '' || formData.phone === '' || formData.ifsc === ''|| formData.account === '' || formData.confirm === '' || formData.state === '' || formData.city === '' || formData.address === '' || formData.email === '' || formData.account !== formData.confirm) {
        setDialog({ ...openDialog, open: true, body: 'Please Enter all fields correctly'})
      }else{
        try {
        
          setOtpDialog({otpDialog, open: true});
          // const { data } = await api.upi(upi);
          // dispatch({ type: "upi", data: data });
          // history.push("/profile");
        
        } catch (error) {
       setDialog({ ...openDialog, open: true, body: error.response.data.error})
      }
      }
      
    setLoading(() => false);
   
    };
     
    const sendOTP = async(e) => {
      setCounter(90);
      setRun(true); 
      const data = {
       
        phone: `${user[0].phone}`,
       };
      
      await axios.post(`${URL}/sendCodeBank`, data); 
      
     
      
      
    
    }
    const [level, setLevel] = React.useState(0);
    const handleLevel = (level) => async (event) =>  {
        setLevel(level);
    }
    return (
        <div style={{paddingBottom: '50px'}}>
          <Dialog
          open={isLoading}
          PaperProps={{
            style: {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            },
          }}
        >
          <Container  align="center" style={{backgroundColor: 'black', opacity: '0.6',height: '100px',paddingTop: "10px"}}>
          <CircularProgress style={{color: 'white',}} />
           <Typography style={{paddingTop: '10px', color: "white" }}>Please Wait!</Typography>
          </Container>
          </Dialog>
          <Dialog
                          open={otpDialog.open}
                          onClose={otpdialogClose}
                  >
                    <Container >
                <Typography style={{paddingTop: '10px'}} align="center">Enter OTP</Typography>
                <Grid container direction="row" alignItems="center" style={{padding:'20px'}}>
                    <Grid item xs={2}>
                      <SmsIcon  />
                    </Grid>
                    <Grid item xs={8}>
                    <FormControl
                      fullWidth
                      variant="filled"
                    >
                      <InputLabel htmlFor="filled-adornment-password">
                        Verification Code
                      </InputLabel>
                      <FilledInput
                        type="number"
                        name="code"
                        id="filled-adornment-password"
                        min={6}
                        max={6}
                        onChange={handleChange}
                        style={{backgroundColor: 'white', textUnderlineOffset: 'none'}}

                        
                      />
                    </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                    <Container style={{backgroundColor:  'orange', height: '30px', width: '55px'}}>
                      <Button onClick={counter === 0 ? sendOTP : counter === 90 ? sendOTP : null} style={{paddingTop: '1px',marginLeft: '-22px', color: 'white', textTransform: 'none'}}>{canRun ? counter === 0 ? 'Resend': counter : 'OTP'}</Button>
                    </Container>
                  </Grid>
                  </Grid>
                  <Container align="center">
                  <Button type="filled" onClick={submitOTP}>Submit</Button>
                  </Container>
                  
                </Container>
               </Dialog> 
            <Grid container direction="row" justify="" alignItems="center" style={{paddingLeft: '20px',paddingTop: '15px',paddingBottom: '15px',paddingRight: '20px', backgroundColor: 'white'}}>
                <Grid item xs={4}>  
                <Link to="../profile">
                <ArrowBackIosIcon style={{fontSize:'20px'}} />
                </Link>
                
                                
                 </Grid>
                 <Grid item xs={4}>                    
                <Typography align="center" st>My Bank Card</Typography>
                 </Grid>
            </Grid>
           
                    <Dialog
                    open={openDialog.open}
                    onClose={dialogClose}
                    PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                    }}
                
                    
                >
                    <Container style={{backgroundColor: 'black', opacity: '0.6',height: '50px'}}>
                <Typography style={{paddingTop: '10px', color: "white" }}>{openDialog.body}</Typography>
                </Container>
                </Dialog>
                <Typography style={{paddingLeft: '30px',paddingTop: '10px',paddingBottom: '15px',paddingRight: '20px', }}>Add bank card</Typography>

                <Container>

            <Typography align="center" style={{fontWeight: 'bold', color: 'orange'}}>For faster withdrawals always use UPI.</Typography>
            <Grid container direction="row" style={{padding: '10px'}}>
                <Grid item xs={6} onClick={handleLevel(0)}>
                    <Container style={{width: '100%', height: '30px', border: '1px solid', borderColor: 'black',backgroundColor: level === 0 ? "orange" : 'white' }}>
                        <Typography align="center" style={{ color: level === 0 ? 'white' : "black", paddingTop: "3px"}}>UPI</Typography>
                    </Container>
                </Grid>
                <Grid item xs={6} onClick={handleLevel(1)}>
                    <Container style={{width: '100%', height: '30px', border: '1px solid', borderColor: 'black',backgroundColor: level === 1 ? "orange" : 'white'}}>
                        <Typography align="center" style={{ color: level === 1 ? 'white' : "black", paddingTop: "3px"}}>Bank Account</Typography>
                    </Container>
                </Grid>
            </Grid>
            </Container>
            {level === 0 ? 
            <div>
            {user && user[0].upi && user[0].upi != null ? 
            <Paper style={{padding: '15px', margin: '15px'}}>
            <Typography align="center" style={{fontWeight: 'bold'}}>Your UPI Details</Typography>

            <Typography style={{paddingTop: '15px'}}>UPI ID: {user && user[0].upi}</Typography>
            </Paper>
               : null} 
            <Grid container direction="row" alignItems="center" style={{paddingRight: '25px', paddingLeft: '25px'}}>
                  <Grid item xs={4}>
                    <Typography>UPI ID: </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth
                      
                      variant="filled"
                     
                    >
                      <InputLabel  >
                      1234567890@paytm
                      </InputLabel>
                      <FilledInput
                        fullWidth='true'
                        name="upi"
                        id="filled"
                       onChange={UPIChange}
                        style={{backgroundColor: 'white', textUnderlineOffset: 'none'}}
                      />
                    </FormControl>
                  </Grid>
                  
                
            </Grid>

            </div>
            :
            <div>   
            {user && user[0].bank[0] && user[0].bank[0] != null? 
            <Paper style={{padding: '15px', margin: '15px'}}>
              <Typography align="center" style={{fontWeight: 'bold'}}>Your Bank Details</Typography>
            <Typography>Name: {user && user[0].bank[0].name}</Typography>
            <Typography>Account: {user && user[0].bank[0].account}</Typography>
            <Typography>IFSC: {user && user[0].bank[0].ifsc}</Typography>
            <Typography>City: {user && user[0].bank[0].city}</Typography>
            <Typography>Phone: {user && user[0].bank[0].phone}</Typography>
            </Paper>
               : null} 
            
            <Grid container direction="row" alignItems="center" style={{paddingRight: '25px', paddingLeft: '25px'}}>
                  <Grid item xs={4}>
                    <Typography>Actual Name: </Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth
                      
                      variant="filled"
                     
                    >
                      <InputLabel  >
                      Enter your name
                      </InputLabel>
                      <FilledInput
                        fullWidth='true'
                        name="name"
                        id="filled"
                       onChange={handleChange}
                        style={{backgroundColor: 'white', textUnderlineOffset: 'none'}}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" style={{paddingRight: '25px', paddingLeft: '25px'}}>
                  <Grid item xs={4}>
                    <Typography>Mobile Number</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth
                      
                      variant="filled"
                     
                    >
                      <InputLabel  >
                      Enter Mobile Number
                      </InputLabel>
                      <FilledInput
                        fullWidth='true'
                        name="phone"
                        type='phone'
                        id="filled"
                        onChange={handleChange}
                        style={{backgroundColor: 'white', textUnderlineOffset: 'none'}}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" style={{paddingRight: '25px', paddingLeft: '25px'}}>
                  <Grid item xs={4}>
                    <Typography>IFSC CODE</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth
                      
                      variant="filled"
                     
                    >
                      <InputLabel  >
                      Enter IFSC CODE
                      </InputLabel>
                      <FilledInput
                        fullWidth='true'
                        name="ifsc"
                        id="filled"
                        onChange={handleChange}
                        style={{backgroundColor: 'white', textUnderlineOffset: 'none'}}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" style={{paddingRight: '25px', paddingLeft: '25px'}}>
                  <Grid item xs={4}>
                    <Typography>Account Number</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth
                      
                      variant="filled"
                     
                    >
                      <InputLabel  >
                      Enter Account Number
                      </InputLabel>
                      <FilledInput
                        fullWidth='true'
                        name="account"
                        type="number"
                        id="filled"
                        onChange={handleChange}
                        style={{backgroundColor: 'white', textUnderlineOffset: 'none'}}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" style={{paddingRight: '25px', paddingLeft: '25px'}}>
                  <Grid item xs={4}>
                    <Typography>Confirm Account Number</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth
                      
                      variant="filled"
                     
                    >
                      <InputLabel  >
                      Confirm Account Number
                      </InputLabel>
                      <FilledInput
                        fullWidth='true'
                        name="confirm"
                        type="number"
                        id="filled"
                        onChange={handleChange}
                        style={{backgroundColor: 'white', textUnderlineOffset: 'none'}}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                
                <Grid container direction="row" alignItems="center" style={{paddingRight: '25px', paddingLeft: '25px'}}>
                  <Grid item xs={4}>
                    <Typography>State</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth
                      
                      variant="filled"
                     
                    >
                      <InputLabel  >
                      Enter State Name
                      </InputLabel>
                      <FilledInput
                        fullWidth='true'
                        name="state"
                        id="filled"
                        onChange={handleChange}
                        style={{backgroundColor: 'white', textUnderlineOffset: 'none'}}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" style={{paddingRight: '25px', paddingLeft: '25px'}}>
                  <Grid item xs={4}>
                    <Typography>City</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth
                      
                      variant="filled"
                     
                    >
                      <InputLabel  >
                      Enter City Name
                      </InputLabel>
                      <FilledInput
                        fullWidth='true'
                        name="city"
                        
                        id="filled"
                        onChange={handleChange}
                        style={{backgroundColor: 'white', textUnderlineOffset: 'none'}}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" style={{paddingRight: '25px', paddingLeft: '25px'}}>
                  <Grid item xs={4}>
                    <Typography>Address</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth
                      
                      variant="filled"
                     
                    >
                      <InputLabel  >
                      Enter Your Address
                      </InputLabel>
                      <FilledInput
                        fullWidth='true'
                        name="address"
                        id="filled"
                        onChange={handleChange}
                        style={{backgroundColor: 'white', textUnderlineOffset: 'none'}}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container direction="row" alignItems="center" style={{paddingRight: '25px', paddingLeft: '25px'}}>
                  <Grid item xs={4}>
                    <Typography>Email</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <FormControl fullWidth
                      
                      variant="filled"
                     
                    >
                      <InputLabel  >
                      Enter Email Address 
                      </InputLabel>
                      <FilledInput
                        fullWidth='true'
                        name="email"
                        id="filled"
                        onChange={handleChange}
                        style={{backgroundColor: 'white', textUnderlineOffset: 'none'}}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                </div> 
                }
                    
                <Container onClick={level === 0 ? handleUPI : handleSubmit} style={{paddingTop:'12px',margin:'20px', backgroundColor:'orange',height: '50px', borderRadius: '10px'}}>
                <Grid container direction="row" justify="space-evenly">
                    <Grid item>
                        <Typography style={{ color: "white", fontWeight: "bold" }}>Update Now</Typography>
                    </Grid>
                    </Grid>
                </Container>
            
            </div>
            
            
        
    )
}

export default MyBank ; 
