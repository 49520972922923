import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import { Link } from "react-router-dom";
import HomeIcon from "../images/homeWingo.png";
import Home from "../images/home.png";
import WinGo from "../images/wingo.png";
import WinGo0 from "../images/wingo1.png";
import MyIconColor from "../images/myColor.png";
import MyIcon from "../images/my.png";



const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
});

export default function NavBar() {
  const classes = useStyles();
  const pathname = window.location.pathname; // in case user visits the path directly. The BottomNavBar is able to follow suit.
    const [value, setValue] = React.useState(pathname);
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    return (
      
      <BottomNavigation
      value={value} onChange={handleChange} showLabels={true}
        className={classes.root}
      >
        {/* <BottomNavigationAction label="home" value="/" icon={<img src={value === '/' ? Home : HomeIcon} height={20} alt="HomeIcon"/>} component={Link} to='../' style={{color: value === '/' ?'orange':'grey'}}/> */}
        {localStorage.getItem('user') ? <BottomNavigationAction label="winGo" value="/wingo" icon={<img src={value === '/wingo' ? WinGo : WinGo0} height={20} alt=""/>} component={Link} to='/wingo' style={{color: value === '/wingo' ?'orange':'grey'}}/> : null}              
        <BottomNavigationAction label="my" value={localStorage.getItem('user') ?"/profile" : "/login"} icon={<img src={value === "/profile" ? MyIconColor : MyIcon} height={20} alt="myicon"/>} component={Link} to={localStorage.getItem('user') ?"/profile" : '/login'} style={{color: value === '/profile' ?'orange':'grey'}}/>
      </BottomNavigation>
      
    );
}
