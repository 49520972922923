import React from 'react';
import {Typography, Grid } from '@material-ui/core/';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from "react-router-dom";



const Contact = () => {

    return (
        <div>
            <Grid container direction="row" justify="" alignItems="center" style={{paddingLeft: '20px',paddingTop: '15px',paddingBottom: '15px',paddingRight: '20px', backgroundColor: 'white'}}>
                <Grid item xs={4}>  
                <Link to="../">
                <ArrowBackIosIcon style={{fontSize:'20px'}} />
                </Link>
                
                                
                 </Grid>
                 <Grid item xs={4}>                    
                <Typography align="center" variant="h6">Contact us</Typography>
                 </Grid>
            </Grid>
            
            {/* <Typography align="center"  display="block"  style={{fontSize:'10px', padding: '20px'}}>E-mail: ankitrt6@gmail.com
</Typography> */}
{/* <Typography align="center"  display="block"  style={{fontSize:'10px', padding: '20px'}}>Mobile: +919981583961
</Typography>
<Typography align="center"  display="block"  style={{fontSize:'10px', padding: '20px'}}>Address: 107, Ankpat Marg Kajipura,456010, Ujjain, Madhya Pradesh, India
</Typography> */}
            

            
        </div>
    )
}

export default Contact ; 
